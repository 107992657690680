<template>
  <dashboard-layout>
    <div class="w-full p-5">
      <router-link
        :to="{ name: 'list-terms' }"
        class="bg-green-1000 text-white px-6 py-3 rounded rounded-md border border-gray-400 focus:outline-none"
      >
        <svg
          style="display: initial;"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          />
        </svg>
        voltar
      </router-link>
      <div class="p-4 flex">
        <h1 class="text-3xl">
          Criar Termo
        </h1>
      </div>
      <form class="p-4 flex flex-col" @submit.prevent="submit">
        <div class="mb-3 flex flex-col">
          <label class="mb-3" for="title">Título</label>
          <input
            type="text"
            name="title"
            id="title"
            v-model="title"
            class="focus:outline-none p-2 rounded-lg"
          />
        </div>
        <div class="mb-3 flex flex-col">
          <label class="mb-3" for="term">Termo</label>
          <rich-text-editor
            id="term"
            v-model="term"
            classes="h-96 max-h-96"
          ></rich-text-editor>
        </div>
        <div class="mb-3 flex flex-col">
          <label class="mb-3" for="obs">Observações</label>
          <rich-text-editor
            id="obs"
            v-model="obs"
            classes="h-40 max-h-40"
          ></rich-text-editor>
        </div>
        <div class="mb-3 flex justify-between">
          <router-link
            class="bg-red-400 text-white px-6 py-3 rounded rounded-md focus:outline-none"
            :to="{ name: 'list-terms' }"
          >
            Cancelar
          </router-link>
          <button
            class="bg-green-1000 text-white px-6 py-3 rounded rounded-md border border-gray-400 focus:outline-none"
          >
            Salvar
          </button>
        </div>
      </form>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import axios from '@/utils/axios';
import RichTextEditor from '../../../components/RichTextEditor';

export default {
  name: 'CreateTerm',

  components: {
    DashboardLayout,
    RichTextEditor
  },

  data() {
    return {
      title: '',
      term: '',
      obs: ''
    };
  },

  methods: {
    submit() {
      return axios
        .post('/api/termos', {
          title: this.title,
          term: this.term,
          obs: this.obs
        })
        .then(({ data }) => {
          this.$router.push({ name: 'list-terms' });

          this.$toast.success(data.data.message);
        })
        .catch(({ response }) => {
          Object.values(response.data.errors).forEach(error => {
            this.$toast.error(...error);
          });
        });
    }
  }
};
</script>

<style></style>
